.small-section {
  padding: 120px 0 100px 0;
}

.hidden {
  display:none !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
