// Bootstrap and other libraries should be imported in vendor.scss in order to create a different chunk for caching purposes
// If we import bootstrap here as well we would have duplicated css increasing the page loading time,
// however, the variables scss is shared between the app and the libraries, therefore, you can use it to override variables like:
// bootstrap $primary, $secondary, $grid-gutter-width, etc.
@import "variables";

// Imports required to use bootstrap variables that are not overridden by _variables.scss
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "template-overrides";

html {
  font-size: 16px; // 1rem
  background-color: $dark;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.floated-image {
  float: right;
  margin-left: 20px;
  clear: both;
}

.language-activities {
  width: 250px;
  border: 3px solid white;
  border-radius: 10px;
}

.postcards-list{
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow:column
}

.image-placeholder {
    background-color: rgba(238, 238, 238, 0.71);
    display: flex;
}

.image-placeholder > h4 {
    font-size: 50px;
    color: rgba(238, 238, 238, 0.3);
}

.constellation-icon {
  height: 120px;
  width: 120px;
}

.constellation-image {
  height: 590px;
  width: 670px;
}

.campaign-item.is-past {
  opacity: 0.5;
  font-size: 1rem;

  .campaign-title {
    font-size: 1.2rem;
  }

  img {
    width: 70px;
  }

  .image-placeholder {
    width: 70px !important;
    height: 70px !important;
  }
}
